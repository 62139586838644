
import { defineComponent } from 'vue'
import { ChatDotRound } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'CommentItem',
  props: ['article'],
  components: {
    ChatDotRound,
  },
  setup(props, content) {},
})
