
import { defineComponent, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

import { getAddStar, unStar } from '../../service/article/index'

import { formatUtcString } from '../../utils/date'

import ArticleActive from './ArticleActive.vue'

import { Edit } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'ArticleCard',
  props: {
    articleData: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ArticleActive,
    Edit,
  },
  setup(props, content) {
    const router = useRouter()
    const article = props.articleData
    const isEdit = props.isEdit

    const clickArticleInfo = (id: string) => {
      router.push(`/articleinfo/info/${id}`)
    }
    const editArticle = () => {
      console.log(props.articleData)
      router.push({
        path: '/create',
        query: {
          articleId: props.articleData?.articleId,
        },
      })
    }
    return {
      article,
      clickArticleInfo,
      isEdit,
      formatUtcString,
      editArticle,
    }
  },
})
