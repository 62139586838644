
import { defineComponent, ref } from 'vue'
import { House, Edit, User, Wallet, Search } from '@element-plus/icons-vue'

import Serarch from '../../components/Serarch/Search.vue'

export default defineComponent({
  name: 'FooterNav',
  components: {
    Serarch,
  },
  setup(props, content) {
    let searchDialogVisible = ref(false)
    const isOkSearch = () => {
      searchDialogVisible.value = false
    }
    return {
      House,
      Edit,
      User,
      Wallet,
      Search,
      searchDialogVisible,
      isOkSearch,
    }
  },
})
