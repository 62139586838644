import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70512b3b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ArticleActive" }
const _hoisted_2 = { class: "activeBox" }
const _hoisted_3 = { class: "active" }
const _hoisted_4 = { class: "active" }
const _hoisted_5 = { class: "active" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Stopwatch = _resolveComponent("Stopwatch")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_View = _resolveComponent("View")!
  const _component_CommentItem = _resolveComponent("CommentItem")!
  const _component_sapn = _resolveComponent("sapn")!
  const _component_StarItem = _resolveComponent("StarItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_icon, { class: "icon" }, {
          default: _withCtx(() => [
            _createVNode(_component_Stopwatch)
          ]),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.formatUtcString(_ctx.article.createTime)), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_icon, { class: "icon" }, {
          default: _withCtx(() => [
            _createVNode(_component_View)
          ]),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.article.articleViews), 1)
      ]),
      _renderSlot(_ctx.$slots, "comment", {}, () => [
        _createVNode(_component_sapn, { class: "active" }, {
          default: _withCtx(() => [
            _createVNode(_component_CommentItem, { article: _ctx.article }, null, 8, ["article"])
          ]),
          _: 1
        })
      ], true),
      _renderSlot(_ctx.$slots, "star", {}, () => [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_StarItem, { article: _ctx.article }, null, 8, ["article"])
        ])
      ], true),
      _createElementVNode("span", {
        class: "active",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goAuthor && _ctx.goAuthor(...args)), ["stop"]))
      }, [
        _renderSlot(_ctx.$slots, "author", {}, undefined, true)
      ])
    ])
  ]))
}