import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatDotRound = _resolveComponent("ChatDotRound")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("span", {
    class: "CommentItem",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push(`/articleinfo/info/${_ctx.article.articleId}#comment`)), ["stop"]))
  }, [
    _createVNode(_component_el_icon, { class: "icon" }, {
      default: _withCtx(() => [
        _createVNode(_component_ChatDotRound)
      ]),
      _: 1
    }),
    _createTextVNode(" " + _toDisplayString(_ctx.article ? _ctx.article.articleComments : 0), 1)
  ]))
}