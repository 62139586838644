
import { defineComponent, ref, watchEffect } from 'vue'

import { getAddStar, unStar } from '../../../service/article/index'

import {Star } from '@element-plus/icons-vue'

import message from '../../../utils/message'

export default defineComponent({
  name: 'StarItem',
  props: ['article'],
  components: {
    Star,
  },
  setup(props, content) {
    let starNum = ref(0)
    const color = '#777'
    watchEffect(() => {
      starNum.value = props.article?.articleStar
    })
    const addStarFun = (id: string) => {
      addStar(id)
    }
    async function addStar(id: string) {
      const data = await getAddStar({ articleId: id })
      if (data.success) {
        starNum.value++
      } else {
        message.warning(data.data)
      }
    }
    const unStarFun = (id: string) => {
      deleteStar(id)
    }
    async function deleteStar(id: string) {
      const data = await unStar(id)
      if (data.success) {
        starNum.value--
      } else {
        message.warning(data.data)
      }
    }
    return {
      starNum,
      addStarFun,
      unStarFun,
      color
    }
  },
})
