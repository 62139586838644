import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9172a380"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "StarItem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Star = _resolveComponent("Star")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    ((_ctx.starNum === _ctx.article.articleStar) == !_ctx.article.liked)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "active",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.addStarFun(_ctx.article.articleId)), ["stop"]))
        }, [
          _createVNode(_component_el_icon, {
            class: "icon",
            color: (_ctx.starNum === _ctx.article.articleStar) == !_ctx.article.liked ? _ctx.color : '#C62828'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Star)
            ]),
            _: 1
          }, 8, ["color"]),
          _createTextVNode(" " + _toDisplayString(`${_ctx.starNum ? _ctx.starNum : 0}`), 1)
        ]))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "active",
          style: {"color":"#c62828"},
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.unStarFun(_ctx.article.articleId)), ["stop"]))
        }, [
          _createVNode(_component_el_icon, {
            class: "icon",
            color: (_ctx.starNum === _ctx.article.articleStar) == !_ctx.article.liked ? _ctx.color : '#C62828'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Star)
            ]),
            _: 1
          }, 8, ["color"]),
          _createTextVNode(" " + _toDisplayString(`${_ctx.starNum ? _ctx.starNum : 0}`), 1)
        ]))
  ]))
}