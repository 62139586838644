
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import AuthorCard from './AuthorCard.vue'

import { getFollows, getFans } from '../../../service/user/user'
import { IDataType } from '../../../service/user/type'

import message from '../../../utils/message'

export default defineComponent({
  name: 'AuthorList',
  props: {
    isInsertActive: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  components: {
    AuthorCard,
  },
  setup(props, content) {
    const isInsertActive = props.isInsertActive
    const route = useRoute()
    let current = 1
    let size = 10
    let followsAllList = ref<any>([])
    let isNullFollow = ref(false)
    let loading = ref(false)

    //获取关注和粉丝列表
    async function getFollowsFun() {
      loading.value = true
      let followsData!: IDataType
      switch (props.type) {
        case 'default':
          followsData = await getFollows({
            userId: route.params.userid + '',
            current,
            size,
            orderBy: 'createTime.desc',
          })
          break
        case 'fan':
          followsData = await getFans({
            userId: route.params.userid + '',
            current,
            size,
            orderBy: 'createTime.desc',
          })
          break
        default:
          isNullFollow.value=true
          return
      }
      if (followsData.success) {
        if (followsData.data.pages === followsData.data.current || followsData.data.pages===0) {
          isNullFollow.value = true
          loading.value=false
        }
        current++
        loading.value = false
        followsAllList.value.push(...followsData.data.records)
      } else {
        message.error(followsData.data)
        loading.value = false
      }
    }
    onMounted(() => {
      getFollowsFun()
    })
    return {
      isInsertActive,
      getFollowsFun,
      followsAllList,
      isNullFollow,
      loading
    }
  },
})
