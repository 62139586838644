
import { defineComponent, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

import { formatUtcString } from '../../utils/date'

import { getAddStar, unStar } from '../../service/article/index'

import CommentItem from './cnps/CommentItem.vue'
import StarItem from './cnps/StarItem.vue'

import { Edit, Stopwatch, View, ChatDotRound, Star } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'ArticleActive',
  props: ['article'],
  components: {
    Edit,
    Stopwatch,
    View,
    CommentItem,
    StarItem,
  },
  setup(props, content) {
    const router = useRouter()
    const goAuthor = () => {
      router.push({
        name: 'usermenu',
        path: '/usermenu',
        params: {
          userid: props.article.author.userId,
        },
      })
    }
    return {
      formatUtcString,
      goAuthor,
    }
  },
})
