
import { defineComponent, ref } from 'vue'
import {useRouter} from 'vue-router'

import { getById, follow, unFollow } from '../../../service/user/user'

import message from '../../../utils/message'

import FollowedItem from './FollowedItem.vue'

export default defineComponent({
  name: 'AuthorCard',
  props: ['authorData'],
  components:{
    FollowedItem
  },
  setup(props, content) {
    const router = useRouter()
    const goAuthor = () => {
      router.push({
        name: 'usermenu',
        path: '/usermenu',
        params: {
          userid: props.authorData.userId,
        },
      })
    }
    return{
      goAuthor
    }
  },
})
